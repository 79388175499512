// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

import Editor from '@tinymce/tinymce-vue';
Vue.use(Editor)

import VueSimpleAlert from "vue-simple-alert"
Vue.use(VueSimpleAlert)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

import JsonExcel from "vue-json-excel"
Vue.component("downloadExcel", JsonExcel)

import JsonViewer from 'vue-json-viewer/ssr'
Vue.use(JsonViewer)
import 'vue-json-viewer/style.css'

import VueGtag from "vue-gtag";
Vue.use(VueGtag, {
  config: { id: "UA-126572234-10" }
});

new Vue({
  router,
  store,
  vuetify,
  // RichTextEditorPlugin,
  // DocumentEditorContainerPlugin,
  // PdfViewerPlugin,
  // DocumentEditorPlugin,
  Editor,
  i18n,
  render: h => h(App),
}).$mount('#app')
